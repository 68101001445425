import {useRouter} from "next/router";
import filterService from "../../../services/filterService";
import {useDispatch} from "react-redux";
import ensureArray from "../../../utils/ensureArray";

export default function FilterSelectedDefault({options, filterKey}: any) {
    const router = useRouter();
    const opts = ensureArray(options);
    const dispatch = useDispatch();

    return (
        <>
            {opts.map((option: any) => (
                <div key={option}
                     role={"button"}
                     onClick={() => filterService.removeFilter(opts, option, filterKey, router, false, dispatch, router.query)}
                     className={"btn-filter border-1 border rounded-5 ps-3 pe-3 pt-2 pb-2"}>
                    <span>{option}</span>
                    <i className="fa-regular fa-circle-xmark ms-2"></i>
                </div>
            ))}
        </>
    );
}
