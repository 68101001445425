import React from "react";
import Image from "next/image";
import Link from "../link/Link";
import {convertBxAttributes} from "../../utils/bxAttributes";

interface Props {
    data: any;
    isLoading?: boolean
    className?: string;
}

export default function PromotionTile({ data, className = '', isLoading = false }: Props) {
    if (!data.image) return <></>

    return (
        <div {...convertBxAttributes(data, `promotion-tile text-center rounded-4 position-relative ${className} ${isLoading ? 'hidden' : ''}`)}>
            <Link href={data.link}>
                <Image src={data.image}
                       className="rounded-4"
                       alt={data.altText}
                       fill
                       sizes="(min-width: 1200px) 203px, (min-width: 540px) 248px, 46.36vw"/>
            </Link>
        </div>
    );
}
