import {useRouter} from "next/router";
import filterService from "../../../services/filterService";
import {useEffect, useState} from "react";
import apiInternal from "../../../utils/apiInternal";
import {useDispatch} from "react-redux";
import ensureArray from "../../../utils/ensureArray";

export default function FilterSelectedCategory(props: any) {
    const router = useRouter();
    const ids = ensureArray(props.options);
    const key = props.filterKey;
    const [categories, setCategories] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const fn = async () => {
            const result: any = await apiInternal().post('/api/v2/category/ids', {ids: ids});
            if (result.status !== 200 || !result.data) return;
            setCategories(result.data);
        };

        fn().then();
    }, [])

    if (!categories.length) return <></>

    return (
        <>
            {categories.map((category: any) => (
                <div key={category.id}
                     role={"button"}
                     onClick={() => filterService.removeFilter(ids, category.id.toString(), key, router, false, dispatch, router.query)}
                     className={"btn-filter border-1 border rounded-5 ps-3 pe-3 pt-2 pb-2"}>
                    <span>{category.name}</span>
                    <i className="fa-regular fa-circle-xmark ms-2"></i>
                </div>
            ))}
        </>
    )
}
