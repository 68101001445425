import BxFilterKey from "./bxFilterKey";
import BxFilterPrefix from "./bxFilterPrefix";

class BxFilterPrefixBuilder {
    getPrefix(field: any) {
        if (field === BxFilterKey.brand) return BxFilterPrefix.brand;
        if (field === BxFilterKey.category) return BxFilterPrefix.category;
        if (field === BxFilterKey.categories) return BxFilterPrefix.categories;
        if (field === BxFilterKey.price) return BxFilterPrefix.price;
        if (field.includes(BxFilterKey.property)) {
            const s = field.split('_');
            if (!s.length) return 0;
            return s[s.length - 1];
        }
    }
}

    const bxFilterPrefixBuilder = new BxFilterPrefixBuilder();
export default bxFilterPrefixBuilder