import React from "react";
import i18next from "i18next";
import Link from "../link/Link";
import Image from "next/image";
import {convertBxAttributes} from "../../utils/bxAttributes";
import blogLinkService from "../../services/blog/blogLinkService";

interface Props {
    blog: any;
    className?: string;
    isLoading?: boolean;
}

export default function BlogTile({ blog, className = '', isLoading = false }: Props) {
    if (!blog) return <></>

    return (
        <div {...convertBxAttributes({ 'bx-attributes': blog.bxAttributes }, `blog-tile border-bottom border-1 position-relative ${className} ${isLoading ? 'hidden' : ''}`)}>
            <Link href={blogLinkService.getLink(blog)} passHref
                  prefetch={false} className="d-block dark">
                <Image src={blog.image}
                       width={600}
                       height={600}
                       className={"w-100 h-auto"}
                       sizes="(min-width: 1200px) 406px, (min-width: 780px) 230px, (min-width: 580px) 516px, calc(100vw - 24px)"
                       alt={""}/>

                <div className={"p-1 pd-xl-4 mt-3"}>
                    <p className={"fw-bold"}>{blog.title}</p>
                    <p>{blog.subtitle}</p>
                    <div className={"text-decoration-underline mt-2"}>{i18next.t('general.readMore').toString()}</div>
                </div>
            </Link>
        </div>
    )
}
