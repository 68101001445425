import {useRouter} from "next/router";
import filterService from "../../services/filterService";
import i18next from "i18next";
import {useDispatch} from "react-redux";

export default function FilterReset() {
    const router = useRouter();
    const dispatch = useDispatch();

    return (
        <div className={"btn-filter bg-black rounded-5 text-white ps-3 pe-3 pt-2 pb-2 me-2"}
             role={"button"}
             onClick={() => filterService.resetFilter(router, dispatch)}>
            <span>{i18next.t('filter.removeAll').toString()}</span>
            <i className="fa-solid fa-circle-xmark ms-2"></i>
        </div>
    )
}