import i18next from "i18next";
import {useRouter} from "next/router";
import filterService from "../../services/filterService";
import routeService from "../../services/route/routeService";
import {Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper} from "@mui/material";
import {useRef, useState} from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const config = [
    {
        field: 'score',
        i18nKey: 'sort.recommendation',
        reverse: true
    },
    {
        field: 'creation',
        i18nKey: 'sort.novelty',
        reverse: true
    },
    {
        field: 'sales',
        i18nKey: 'sort.popularity',
        reverse: true
    },
    {
        field: 'discountedPrice',
        i18nKey: 'sort.priceAsc',
        reverse: false
    },
    {
        field: 'discountedPrice',
        i18nKey: 'sort.priceDesc',
        reverse: true
    },
    {
        field: 'bq_std_discount_percent',
        i18nKey: 'sort.discountPercent',
        reverse: true
    },
    {
        field: 'title',
        i18nKey: 'sort.name',
        reverse: false
    }
] as const;

interface Props {
    filterGroups: any;
}

export default function FilterSortOption({ filterGroups }: Props) {
    const router = useRouter();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [selectedField, setSelectedField] = useState(router.query.sort ?? filterGroups?.filters?.sort ?? 'score');
    const [isReversed, setIsReversed] = useState(router.query.reverse === undefined || router.query.reverse === 'true');

    async function sort(type: string, reverse: boolean) {
        let query = filterService.getQuery(router.query);
        query.page = 1;
        query.sort = type;
        query.reverse = reverse;

        await router.push({
                pathname: routeService.getAsPath(router.asPath),
                query: query
            }
        )
    }

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const handleMenuItemClick = (
        field: string,
        reverse: boolean
    ) => {
        setOpen(false);
        setSelectedField(field);
        setIsReversed(reverse)
        return sort(field, reverse);
    };

    function getLabel() {
        const key = config.find(({field, reverse}) => selectedField === field && isReversed === reverse)?.i18nKey;
        return i18next.t(key ?? 'sort.recommendation').toString();
    }

    return (
        <div className={"d-inline"}>
            <div ref={anchorRef} className={"position-relative"}>
                <Button onClick={() => setOpen((prevOpen) => !prevOpen)}
                        color={"primary"}
                        variant={"outlined"}
                        fullWidth
                        sx={{
                            borderColor: "#000",
                            borderRadius: "6px",
                            '&:hover': {
                                borderColor: "#000",
                            }
                        }}>
                    <span style={{textTransform: 'none'}}>{getLabel()}</span>
                    <ArrowDropDownIcon className={"position-absolute top-0 end-0 me-2 mt-1 pt-1"}
                                       sx={{height: "25px", width: "25px"}}/>
                </Button>
                <Popper
                    sx={{
                        zIndex: 1,
                        width: "100%"
                    }}
                    open={open}
                    role={undefined}
                    anchorEl={anchorRef.current}
                    transition
                    disablePortal>
                    {({TransitionProps, placement}) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom' ? 'center top' : 'center bottom',
                            }}>
                            <Paper sx={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                                borderTop: "1px solid rgba(0, 0, 0, 0.1)"
                            }}>
                                <ClickAwayListener onClickAway={handleClose}>

                                    <MenuList id="split-button-menu" autoFocusItem>
                                        {config.map(({field, i18nKey, reverse}) =>
                                            <MenuItem key={`${field}-${reverse ? 'desc' : 'asc'}`}
                                                      selected={selectedField === field && isReversed === reverse}
                                                      sx={{textAlign: 'center', display: 'block'}}
                                                      onClick={() => handleMenuItemClick(field, reverse)}
                                            >
                                                {i18next.t(i18nKey).toString()}
                                            </MenuItem>
                                        )}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}
