import React, {useState} from "react";
import {useRouter} from "next/router";
import filterService from "../../../services/filterService";
import i18next from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../stores/store";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import ensureArray from "../../../utils/ensureArray";

export default function FilterOptionCheckbox(props: any) {
    const query = useSelector((state: State) => state.filterState.query);
    const router = useRouter();
    const key = props.filterKey;
    const selected = ensureArray(query[key] ?? []);
    const [term, setTerm] = useState('');
    const dispatch = useDispatch();

    const opts = Array.isArray(props.options) ? props.options : [props.options];
    let o = opts.map((option: any) => {
        if (!option.value) return;
        return {
            name: option.value,
            value: option.value,
            hitCount: option.hitCount
        }
    });

    const options = o.filter((a: any) => a);
    const [list, setList] = useState(options);

    function search(term: string) {
        setTerm(term);
        const result = filterService.search(options, term);
        term.length ? setList(result) : reset();
    }

    async function close() {
        await filterService.applyFilters(router, dispatch, query, false);
        props.close();
    }

    function reset() {
        setList(options);
        setTerm('');
    }

    if (!options.length) return <></>

    return (
        <>
            <form className={""}>
                <div className="d-flex flex-row input-text-icon flex-fill mb-2 mb-md-0">
                    <input type="text" className="form-control w-100 fs-8"
                           value={term}
                           onInput={(e: any) => search(e.target.value)}
                           placeholder={i18next.t('general.search').toString()}/>
                    <div>
                        <button type="button" className="btn h-100 no-hover" aria-label={"Filter search"}>
                            {term.length ? (
                                <i className="fa-sharp fa-solid fa-circle-xmark mt-2 mt-md-0"
                                   onClick={() => reset()}/>
                            ) : (
                                <i className="fa-solid fa-magnifying-glass mt-2 mt-md-0"/>
                            )}
                        </button>
                    </div>
                </div>
                <div className={"mt-3 ps-1"}>
                    <button type={"button"}
                            onClick={() => close()}
                            className={"btn btn-black text-white w-100 pt-2 pb-2"}>
                        {i18next.t('filter.applySelected').toString()}
                    </button>
                </div>
            </form>
            <form>
                <div className="mt-3 pb-5 h-100">
                    {props.isOpen ? (
                        <>
                            <FormGroup>
                                {list.map((option: any) => (
                                    <FormControlLabel key={option.name}
                                                      control={
                                                          <Checkbox checked={selected.includes(option.value)}
                                                                    onChange={() => filterService.changeHandler(selected, option.value, key, router, props.shallow, dispatch, query)} />
                                                      } label={`${option.name} (${option.hitCount})`} />
                                ))}
                            </FormGroup>
                        </>
                    ) : <></>}
                </div>
            </form>
        </>
    )
}
