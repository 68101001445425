import React, {useEffect, useState} from "react";
import {useRouter} from "next/router";
import filterService from "../../../services/filterService";
import apiInternal from "../../../utils/apiInternal";
import i18next from "i18next";
import {tree} from "../../../utils/tree";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../stores/store";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import ensureArray from "../../../utils/ensureArray";

const allowedCategoryIds = new Set([
    // Haare
    5, 172, 328,
    // Beauty
    52, 262, 329,
    // Parfum
    2235694, 2235748, 2235778,
    // Männer
    2243550, 2243646, 2243689,
    // Dermokosmetik
    2245474, 2245504, 2245534,
]);

export default function FilterOptionCategory(props: any) {
    const query = useSelector((state: State) => state.filterState.query);
    const options = props.options;
    const [list, setList] = useState<any[]>([]);
    const [term, setTerm] = useState('');
    const [categories, setCategories]: any = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [searchIds, setSearchIds] = useState<number[]>([]);
    const router = useRouter();
    const key = 'cf';
    const filterQuery = query.cf;
    const selected = ensureArray(filterQuery ?? []);
    const dispatch = useDispatch();

    useEffect(() => {
        const root = options[0];
        if (!root.children.length) return;
        const c = root.children
            .find((c: any) => parseInt(c.value) === tree(i18next.language))
            .children
            .filter((c: any) => allowedCategoryIds.has(Number(c.value)));
        setCategories(c);
        setList(c);
    }, [options]);

    async function search(term: string) {
        setLoading(true);
        reset();
        setTerm(term);
        let ids: any = [];
        ids = recursiveSearch(categories, ids, term);
        const result: any = await apiInternal().post('/api/v2/category/parent/ids', {ids: ids});
        if (result.status !== 200) return;
        ids = [...ids, ...result.data];
        setSearchIds(ids);
        term.length ? setList(list.filter((i: any) => ids.includes(parseInt(i.value)))) : reset();

        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }

    function recursiveSearch(categories: any, ids: any, term: string) {
        categories.map((category: any) => {
            if (category.label.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(term.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase())) {
                ids.push(parseInt(category.value));
            }
            if (!category.children.length) return;
            return recursiveSearch(category.children, ids, term);
        });

        return ids;
    }

    function reset() {
        setList(categories);
        setTerm('');
        setSearchIds([]);
    }

    async function close() {
        await filterService.applyFilters(router, dispatch, query, false);
        props.close();
    }

    function render(categories: any, recursion: number = 0) {
        if (!Array.isArray(categories)) categories = [categories];

        return (
            <>
                <FormGroup>
                    {categories.map((category: any) => (
                        <div key={category.value} className={`${searchIds.length && !searchIds.includes(parseInt(category.value)) ? 'd-none' : 'd-block'}`}>
                            <FormControlLabel control={
                                                  <Checkbox checked={selected.includes(category.value)}
                                                            onChange={() => filterService.changeHandler(selected, category.value, key, router, props.shallow, dispatch, query)} />
                                              } label={`${category.label} (${category.hitCount})`} />

                            {category.children.length || Object.keys(category.children).length ? (
                                <div className={"ms-4"}>{render(category.children, ++recursion)}</div>
                            ) : <></>}
                        </div>
                    ))}
                </FormGroup>
            </>
        )
    }

    if (!categories.length && !categories) return <></>

    return (
        <>
            <form className={""}>
                <div className="d-flex flex-row input-text-icon flex-fill mb-2 mb-md-0">
                    <input type="text" className="form-control w-100 fs-8 h-100"
                           value={term}
                           onInput={(e: any) => search(e.target.value)}
                           placeholder={i18next.t('general.search').toString()}/>
                    <div>
                        <button type="button" className="btn h-100 no-hover" aria-label={"Filter search"}>
                            {term.length ? (
                                <i className="fa-sharp fa-solid fa-circle-xmark" aria-hidden
                                   onClick={() => reset()}/>
                            ) : (
                                <i className="fa-solid fa-magnifying-glass" aria-hidden/>
                            )}
                        </button>
                    </div>
                </div>
                <div className={"mt-3 ps-1"}>
                    <button type={"button"}
                            onClick={() => close()}
                            className={"btn btn-black text-white w-100 pt-2 pb-2"}>
                        {i18next.t('filter.applySelected').toString()}
                    </button>
                </div>
            </form>
            <form className={"pb-5"}>
                <div className="mt-3 position-relative h-100">
                    {!loading && props.isOpen ?
                        <>
                            {render(list)}

                            {term.length && !list.length ? (
                                <p className={"fs-8 mt-4"}>{i18next.t('filter.emptyResult').toString()}.</p>
                            ) : <></>}
                        </>
                        :
                        <div className={"text-center"}>
                            <i className="fas fa-spinner fa-spin primary-color fs-1 search-spinner ms-auto me-auto mt-5"
                               aria-hidden/>
                        </div>
                    }
                </div>
            </form>
        </>
    )
}
