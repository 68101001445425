import i18next from "i18next";
import React, {useRef, useState} from "react";
import {useRouter} from "next/router";
import routeService from "../../services/route/routeService";
import {Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Link from "next/link";

interface PaginationProps {
    pages: any,
    limit: number,
    load?: any
    persistQuery?: boolean,
    scroll?: boolean,
    scrollTo?: string,
    scrollRef?: React.RefObject<HTMLElement>
}

export default function Pagination(
    {
        pages,
        limit,
        persistQuery = true,
        scroll = true,
        scrollTo,
        scrollRef
    }: PaginationProps) {
    const router = useRouter();
    const [isOpen, setIsOpen] = useState(false);
    let [currentPage, setCurrentPage]: any = useState(router.query.page ?? 1);
    const [selected, setSelected]: any = useState(1);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        page: number
    ) => {
        setOpen(false);
        setSelected(page);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const getRouterQuery = (page: number): any => {
        const q: any = {...router.query};
        q.page = page
        return q;
    }

    const linkHandler = () => {
        scrollRef?.current?.scrollIntoView({behavior: 'smooth', inline: 'start'});
    }

    if (pages < 2) return <></>

    return (
        <div className="row pagination mb-5">
            {(currentPage !== 1 && currentPage > 1) ? (
                <div className={"col-2 col-md-4 text-end"}>
                    <Link
                        href={{pathname: `/${i18next.language}${routeService.getAsPath(router.asPath)}`, query: getRouterQuery(parseInt(currentPage) - 1)}}
                        className="pt-2 border-0 bg-transparent d-block w-100 text-end"
                        scroll={scroll}
                        onClick={linkHandler}
                        aria-label={"Page down"}>
                            <span>
                                <i className="fa-solid fa-circle-chevron-left"></i>
                            </span>
                    </Link>
                </div>
            ) : (
                <div className="col-2 col-md-4 text-end pt-2 pt-md-3"></div>
            )}

            <div className="col-8 col-md-4">
                <div ref={anchorRef} className={"position-relative"}>
                    <Button onClick={handleToggle}
                            color={"primary"}
                            variant={"outlined"}
                            fullWidth
                            endIcon={<ArrowDropDownIcon/>}
                            sx={{
                                borderColor: "rgba(0, 0, 0, 0.23)",
                                borderRadius: "0",
                                borderLeft: 'none',
                                borderRight: 'none',
                                '&:hover': {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                    borderLeft: 'none',
                                    borderRight: 'none',
                                }
                            }}>
                        <span style={{textTransform: 'none'}}>
                            {i18next.t('general.page').toString()} {currentPage} {i18next.t('general.pageOf').toString()} {pages}
                        </span>
                    </Button>
                    <Popper
                        sx={{
                            zIndex: 1,
                            width: "100%"
                        }}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal>
                        {({TransitionProps, placement}) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}>
                                <Paper sx={{
                                    borderRadius: '0',
                                    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                                    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                                    maxHeight: '300px',
                                    overflow: 'auto'
                                }}>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu" autoFocusItem>
                                            {([...Array(pages)].map((_e, i: number) => {
                                                const index = i + 1;
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        selected={index === selected}
                                                        sx={{textAlign: "center", display: "block", p: 0}}
                                                        onClick={(event) => handleMenuItemClick(event, index)}>
                                                        <Link
                                                            href={{pathname: `/${i18next.language}${routeService.getAsPath(router.asPath)}`, query: getRouterQuery(index)}}
                                                            key={index} className="fs-8 text-center no-hover d-block p-1"
                                                            scroll={scroll}
                                                            onClick={linkHandler}
                                                            tabIndex={i}>
                                                            {i18next.t('general.page').toString()} {index} {i18next.t('general.pageOf').toString()} {pages}
                                                        </Link>
                                                    </MenuItem>
                                                )
                                            }))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
            </div>

            {(currentPage !== pages && currentPage < pages) ? (
                <div className={"col-2 col-md-4"}>
                    <Link
                        href={{pathname: `/${i18next.language}${routeService.getAsPath(router.asPath)}`, query: getRouterQuery(parseInt(currentPage) + 1)}}
                        className="pt-2 border-0 bg-transparent d-block w-100 text-start"
                        onClick={linkHandler}
                        scroll={scroll}
                        aria-label={"Page up"}>
                        <i className="fa-solid fa-circle-chevron-right"></i>
                    </Link>
                </div>
            ) : (
                <div className={"col-2 col-md-4 text-end pt-2 pt-md-3"}></div>
            )}
        </div>
    )
}
