import {useRouter} from "next/router";
import filterService from "../../../services/filterService";
import {useDispatch} from "react-redux";

interface Props {
    options: string;
    filterKey: string;
    unit?: string;
    displayFactor?: number;
    displayPrecision: number;
}

export default function FilterSelectedSlider({ options, filterKey, unit = '', displayFactor = 1, displayPrecision }: Props) {
    const router = useRouter();
    const bounds = options.split('_');
    const dispatch = useDispatch();

    function displayedValue(value: string|number) {
        return `${(Number(value) * displayFactor).toFixed(displayPrecision)}${unit}`;
    }

    return (
        <>
            <div role="button"
                 onClick={() => filterService.removeFilter([options], options, filterKey, router, false, dispatch, router.query)}
                 className="btn-filter border-1 border rounded-5 ps-3 pe-3 pt-2 pb-2">
                <span>{displayedValue(bounds[0])} - {displayedValue(bounds[1])}</span>
                <i className="fa-regular fa-circle-xmark ms-2"></i>
            </div>
        </>
    )
}
