import React from "react";
import FilterSortOption from "../filter/SortOption";
import FilterOffcanvas from "../filter/Offcanvas";

export default class BxFilters extends React.Component<any, any> {
    filters: any;
    rows: any;
    excludes: any = ['leaf_category_id'];

    constructor(props: any, context: any) {
        super(props, context);
        this.filters = Array.isArray(props.filters) ? [...props.filters] : [props.filters];

        this.removeExcludes();
    }

    removeExcludes() {
        this.filters = this.filters.filter((f: any) => !this.excludes.includes(f.field));
    }

    render() {
        return (
            <>
                <div className={"row"} style={{height: '40px'}}>
                    <div className={"col-6"}>
                        <FilterSortOption filterGroups={this.props.filterGroups} />
                    </div>
                    <div className={"col-6"}>
                        <FilterOffcanvas filters={this.props.filters}/>
                    </div>
                </div>
            </>
        )
    }
}
