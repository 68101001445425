import React from "react";
import {useRouter} from "next/router";
import i18next from "i18next";
import bxFilterPrefixBuilder from "../../services/boxalino/bxFilterPrefixBuilder";
import Link from "next/link";
import routeService from "../../services/route/routeService";
import {tree} from "../../utils/tree";
import ensureArray from "../../utils/ensureArray";
import {convertBxAttributes} from "../../utils/bxAttributes";

interface Props {
    data: any;
}


export default function FacetInjection({ data }: Props) {
    const router = useRouter();
    const bxFacet = data['bx-facet'];

    if (!bxFacet) {
        return <></>;
    }

    function getTitle() {
        let locale = i18next.language;
        if (locale === 'de') locale = '';
        if (locale.length) {
            const a = locale.substring(0, 1);
            const b = locale.substring(1);
            locale = `${a.toUpperCase()}${b.toLowerCase()}`
        }
        return bxFacet?.[`question${locale}`] ?? '';
    }

    function getName(value: string) {
        return value.split('_bx_')[0];
    }

    function getLink(value: string) {
        const pathname = `/${i18next.language}${routeService.getAsPath(router.asPath)}`;
        const filterPrefix = bxFilterPrefixBuilder.getPrefix(bxFacet.field);
        const query = {
            ...router.query,
            [filterPrefix]:  value
        }
        return {pathname, query};
    }

    function getChildrenOfRootCategory(facetValues: any[], categoryId: number): any[] {
        const category = facetValues.find((c: any) => Number(c.value) === categoryId);
        if (category) {
            return category.children;
        }
        for (const value of facetValues) {
            const categories = getChildrenOfRootCategory(value.children, categoryId);
            if (categories.length) {
                return categories;
            }
        }
        return [];
    }

    function getDisplayedValues(facetValues: any[], limit: number) {
        if (limit <= 0) {
            return [];
        }
        const values = [];
        for (const value of facetValues) {
            if (value.show ?? false) {
                values.push(value);
                if (values.length >= limit) {
                    break;
                }
            }
        }
        return values;
    }

    function getDisplayedCategories(facetValues: any[], limit: number): any[] {
        const shownValues = getDisplayedValues(facetValues, limit);
        if (shownValues.length < limit) {
            for (const value of facetValues) {
                const shownChildren = getDisplayedCategories(ensureArray(value.children), limit - shownValues.length);
                shownValues.push(...shownChildren);
                if (shownValues.length >= limit) {
                    break;
                }
            }
        }
        return shownValues;
    }

    const facetValues = Array.isArray(bxFacet.values) ? bxFacet.values : [bxFacet.values]
    const limit = Number(data.limit ?? 5);
    const displayedValues = bxFacet.field === 'categories'
        ? getDisplayedCategories(getChildrenOfRootCategory(facetValues, tree(i18next.language)), limit)
        : getDisplayedValues(facetValues, limit);


    return (
        <div {...convertBxAttributes(data, 'facet-tile border-light no-hover rounded-3 p-3')}>
            <p className={"text-center mt-3"}>
                <i className="fa-sharp fa-light fa-messages-question fs-2"></i>
            </p>

            <p className={"fw-bold"}>{getTitle()}</p>
            {displayedValues.map((item: any) => (
                <Link key={`fi-${item.value}`}
                      href={getLink(item.value)}
                      prefetch={false}
                      className={"border-light rounded-2 mb-3 ps-2 pe-2 pt-3 pb-3 fs-9 bg-dark text-white d-block"}>
                    {item.fullLabel ?? getName(item.value)} ({item.hitCount})
                    <i className="fa-solid fa-circle-chevron-right ms-2 text-white"></i>
                </Link>
            ))}
        </div>
    );
}

