import React from "react";

export default function Container({className, children, mt = 'mt-5', mb = 'mb-4', contentHeight = 'content-height', container = true}: any) {
    return (
        <>
            <div className={`${container && 'container'} ${contentHeight} ${mt} ${mb} position-relative ${className}`}>
                {children}
            </div>
        </>
    )
}