import React from "react";
import ProductTile from "./Tile";
import Pagination from "../pagination/Default";
import PromotionTile from "../promotion/Tile";
import BlogTile from "../blog/Tile";
import FacetInjection from "../boxalino/FacetInjection";
import {convertBxAttributes} from "../../utils/bxAttributes";
import single from "../../utils/single";

interface Props {
    listing: any,
    total: number,
    bxAttributes?: any,
    disableSidebar?: boolean
    listingRef?: React.RefObject<HTMLElement>
}

export default function ProductList({ listing, total, bxAttributes, disableSidebar, listingRef  }: Props) {
    const limit = 24;
    const pages = Math.ceil(total / limit);

    function getTile(item: any) {
        const entity = single(item.entity);
        if (entity === 'product') {
            return <ProductTile key={item.id} product={item} reviewTotal={true} listing={true}/>
        }

        if (entity === 'image') {
            return <PromotionTile key={item['bx-hit'].id} data={item}/>
        }

        if (entity === 'blog') {
            return <BlogTile key={`blog_${item.id}`} blog={item}/>
        }

        if (item['bx-facet']) {
            return <FacetInjection key={item['bx-facet'].field} data={item}/>
        }
        return <></>;
    }

    return <>
        <div {...convertBxAttributes({ 'bx-attributes': bxAttributes }, `d-flex flex-row flex-wrap mt-4 listing ${disableSidebar && 'wide'} position-relative`)}>
            {listing.map(getTile)}
        </div>

        {pages > 1 && (
            <Pagination pages={pages}
                        limit={limit}
                        scroll={false}
                        scrollTo={'.listing'}
                        scrollRef={listingRef}/>
        )}
    </>;
}
