import filterService from "../../../services/filterService";
import React from "react";
import i18next from "i18next";

export default function FilterOffcanvasDetail({filter, back, close, children}: any) {
    if (!filter) return <></>

    return (
        <>
            <div className="w-100 bg-white filter-detail-container">
                <div className={"ps-3 pe-3 pb-3 mb-3 bg-dark text-white"}>
                    <div className="pt-4 mb-2 link-secondary" onClick={() => back()} role="button">
                        <i className="fa-solid fa-circle-chevron-left"></i>
                        <span className="ms-2 fw-bold">{i18next.t('general.back').toString()}</span>
                    </div>
                </div>
                <p className={"fs-5 mt-2 mb-4 ps-3 pe-3"}>{filterService.getLabel(filter)}</p>
            </div>

            <div className={"ps-3 pe-3"}>
                {children}
            </div>
        </>
    )
}