import FilterReset from "./Reset";
import FilterSelectedDefault from "./selected/Default";
import FilterSelectedSlider from "./selected/Slider";
import FilterSelectedCategory from "./selected/Category";

export default function FilterSelected(props: any) {
    const excluded = ['pr', 'dp', 'sort', 'reverse', 'brand', '?legacy', 'query', 'cf', 'productLine', 'offcanvas'];
    const included = ['s', 'cf', 'pr'];

    const filterGroups = props.filterGroups;
    let filters = filterGroups.filters;
    let list: any = {...filters};
    excluded.forEach((key: any) => delete list[key]);
    const includeList = Object.fromEntries(Object.entries(list).filter(([key]) => included.includes(key) || key.startsWith('property_')));

    if (!Object.keys(filters).length) return <></>

    return (
        <>
            <div className={"position-relative w-100"}>
                <div className={"d-flex flex-wrap gap-2 mt-4"}>
                    {Object.keys(includeList).length || filters.pr || filters.cf ? <FilterReset/> : <></>}

                    {Object.keys(includeList).map((key: any) => (
                        <div className={'d-contents'} key={`filter-selected-${key}`}>
                            {!excluded.includes(key) && (
                                <FilterSelectedDefault key={list[key]}
                                                       filterKey={key}
                                                       options={list[key]}
                                />
                            )}
                        </div>
                    ))}

                    {filters.pr && (
                        <FilterSelectedSlider options={filters.pr}
                                              filterKey={'pr'}
                                              displayPrecision={2}
                        />
                    )}

                    {filters.dp &&
                        <FilterSelectedSlider options={filters.dp}
                                              filterKey={'dp'}
                                              displayFactor={100}
                                              displayPrecision={0}
                                              unit="%"
                        />
                    }

                    {filters.cf && (
                        <FilterSelectedCategory options={filters.cf}
                                                filterKey={'cf'}
                        />
                    )}
                </div>
            </div>
        </>
    )
}
