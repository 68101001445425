import i18next from "i18next";
import {closeFilter, setQuery} from "../stores/filter/filterSlice";
import routeService from "./route/routeService";
import {NextRouter} from "next/router";

class FilterService {
    static excludes = ['category', 'page', 'offset', 'query', 'productLine', 'brand', 'tag', 'offcanvas'];
    static applyExcludes = ['productLine', 'category', 'query', 'brand'];

    async addFilter(selected: string[], value: string, key: string, router: NextRouter, shallow: boolean = false, dispatch: any, query: any) {
        const newSelected = [...selected, value];
        await this.routerPush(key, router, dispatch, query, newSelected, false, shallow);
    }

    async removeFilter(selected: string[], value: string, key: string, router: NextRouter, shallow: boolean = false, dispatch: any, query: any) {
        const p = router.asPath.split('/');
        this.removeSeoFilterProperty(router, p, dispatch);
        this.removeSeoFilterSupplier(router, p, dispatch);

        const newSelected = selected.filter(v => v !== value);
        if (newSelected.length === selected.length) return;
        await this.routerPush(key, router, dispatch, query, newSelected, false, shallow);
    }

    async removeFilterKey(key: string, router: NextRouter) {
        const query = router.query;
        query[key] = '';

        await router.push({
                pathname: routeService.getAsPath(router.asPath),
                query: query
            }, undefined, {
                scroll: false,
            }
        )
    }

    getGroups(query: any) {
        let filters: any = {};
        let optionIds: any = '';

        Object.keys(query).forEach((key: string) => {
            if (!FilterService.excludes.includes(key)) {
                if (query[key].length) {
                    filters[key] = query[key];
                    optionIds += `,${query[key]}`;
                }
            }
        });

        return {
            filters: filters,
            optionIds: optionIds
        }
    }

    getQuery(query: any) {
        const filterGroups = this.getGroups(query);
        let q: any = {};

        if (filterGroups.filters) {
            Object.keys(filterGroups.filters).forEach((key: any) => {
                q[key] = filterGroups.filters[key];
            });
        }
        return q;
    }

    changeHandler(selected: string[], value: string, key: string, router: NextRouter, shallow: boolean = false, dispatch: any, query: any) {
        selected.includes(value) ?
            this.removeFilter(selected, value, key, router, shallow, dispatch, query) :
            this.addFilter(selected, value, key, router, shallow, dispatch, query);
    }

    search(options: any, term: string) {
        options = options.filter((option: any) => option.value.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(term.toLowerCase()));
        return options;
    }

    async routerPush(key: string, router: NextRouter, dispatch: any, query: any, selected?: any, removeGroup: boolean = false, shallow: boolean = false) {
        let q = filterService.getQuery(query);
        q.page = 1;
        if (selected) q[key] = selected;
        if (removeGroup) delete q[key];
        dispatch(setQuery(q));
        if (shallow) return;

        return router.push(
            {
                pathname: routeService.getAsPath(router.asPath),
                query: q
            },
            undefined,
            {
                shallow: shallow,
                scroll: false,
            }
        )
    }

    getLabel(filter: any) {
        if (filter.field === 'discountedPrice') {
            return i18next.t('general.price');
        }
        if (filter.field === 'brand') {
            return i18next.t('general.brand');
        }
        if (filter.field === 'categories') {
            return i18next.t('general.category');
        }
        if (filter.field.startsWith('property_')) {
            return i18next.t(`filter.${filter.field}`);
        }
        return filter.label;
    }

    async applyFilters(router: NextRouter, dispatch: any, query: any, offcanvas: boolean = true) {
        const q: any = Object.fromEntries(Object.entries(query).filter(([key]) => !FilterService.applyExcludes.includes(key)));
        q.offcanvas = offcanvas;

        await router.push({
                pathname: routeService.getAsPath(router.asPath),
                query: q
            }, undefined, { scroll: false }
        );

        dispatch(closeFilter());
    }

    async resetFilter(router: NextRouter, dispatch: any) {
        dispatch(setQuery({}));
        const p = router.asPath.split('/');
        this.removeSeoFilterProperty(router, p, dispatch);
        this.removeSeoFilterSupplier(router, p, dispatch);
        await router.push({
                pathname: routeService.getAsPath(router.asPath),
            }, undefined, {scroll: false}
        )
    }

    removeSeoFilterProperty(router: NextRouter, p: any, dispatch: any) {
        const q = router.query;
        const category = q.category;
        if (typeof category !== 'string' || !category?.includes('?f=')) return;
        q.category = category.split('?')[0];
        dispatch(setQuery({}))
        return router.push({pathname: p.slice(0, p.length - 1).join('/'), query: q});
    }

    removeSeoFilterSupplier(router: NextRouter, p: any, dispatch: any) {
        const q = router.query;
        const category = q.category;

        if (typeof category !== 'string') return;

        try {
            const a = category.split('?');
            const b = a[1];
            const c = b.split('=');
            if (c[1].includes(',')) {
                const d = c[1].trim().split(',');
                if (d.length > 1) return;
            }
            const q = router.query;
            dispatch(setQuery({}))
            q.category = category.split('?')[0];

            return router.push({pathname: p.slice(0, p.length - 1).join('/'), query: q});
        }
        catch(e) {
            return;
        }
    }
}

const filterService = new FilterService();
export default filterService
